
.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #f4f6f9;
}

.login-form-wrapper {
    background: white;
    padding: 2rem 3rem;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

h3 {
    margin-bottom: 1.5rem;
    color: #333;
}

.form-group {
    margin-bottom: 1rem;
    text-align: left;
}

.form-control {
    border-radius: 5px;
    padding: 0.75rem;
    border: 1px solid #ccc;
}

.form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.25);
}

.is-invalid {
    border-color: #dc3545;
}

.invalid-feedback {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.btn-block {
    width: 100%;
    padding: 0.75rem;
    font-weight: 600;
    font-size: 1rem;
    border-radius: 5px;
}

.alert {
    font-size: 0.875rem;
    margin-top: 1rem;
    text-align: center;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}