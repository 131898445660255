.estadisticas-container {
    padding: 20px;
    background-color: #f8f9fa;
  }.contenedor-estadisticas{
    background:linear-gradient(to bottom right, rgb(247, 247, 247),rgb(126, 78, 126));
    min-height: 100vh;
  }
  
  /* Estilos de las tarjetas */
  .estadisticas-card {
    width: 100%;
    max-width: 70%; /* Limita el ancho al 70% */
    margin: 0 auto;
  }
  
  /* Títulos */
  .card-title {
    font-size: 1.6rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Ajuste del tamaño del gráfico */
  .estadisticas-chart {
    width: 100%;
    height: 400px; /* Altura fija para gráficos */
  }
  
  @media (max-width: 768px) {
    /* Ajuste para pantallas pequeñas */
    .estadisticas-card {
      max-width: 100%;
    }
  
    .estadisticas-chart {
      height: 300px; /* Menor altura para móviles */
    }
  }