.perfil-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 50px auto;
  padding: 20px;
  gap: 20px;
}

/* Sidebar */
.sidebar {
  background-color: #f4f4f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  width: 100%;
  flex-shrink: 0;
}

.sidebar h2 {
  margin: 0;
  font-size: 1.5em;
}

.sidebar p {
  margin: 10px 0;
  color: #555;
}

.cerrar-sesion {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

/* Main Content */
.perfil-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Header */
.header {
  background-color: #e6f0ff;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.welcome-msg {
  font-size: 1.3em;
  color: #333;
}

/* URL List */
.urls-list {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.urls-list h3 {
  margin-bottom: 15px;
  color: #333;
}

.url-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
  flex-wrap: wrap;
}

.url-item:last-child {
  border-bottom: none;
}

.url-link {
  color: #007bff;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 70%;
}

.url-link:hover {
  text-decoration: underline;
}

.short-url {
  font-size: 0.9em;
  color: #8d8c8c;
}

/* Edit Menu */
.edit-url {
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 150px;
  border: 1px solid gray;
  border-radius: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

}

.edit-url div {
  margin: 5px 0;
  color: #555;
  cursor: pointer;
}

.edit-url div:hover {
  color: #333;
}

/* Graph Section */
.grafico {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.grafico h3 {
  margin-bottom: 15px;
  color: #333;
}

/* Error Message */
.error {
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background-color: #fbeaea;
  border: 1px solid #e0b4b4;
  border-radius: 8px;
}

/* Responsive Design */
@media (min-width: 768px) {
  .perfil-container {
    flex-direction: row;
  }

  .perfil-main {
    padding: 0 20px;
  }
}

@media (max-width: 768px) {
  .grafico, .urls-list, .header, .sidebar {
    width: 100%;
  }

  .url-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .url-link {
    max-width: 100%;
  }
}.boton-guardar{
  width: 90px;
}
.base-url{
  color: gray;
  font-size: 0.9rem;

}.short-url-editable {
  border: 1px dashed transparent; /* Cambiar a un borde transparente por defecto */
  border-radius: 4px;
  transition: background-color 0.3s, border-color 0.3s;
}

.short-url-editable.highlight {
  padding: 5px;
  background-color: rgba(0, 123, 255, 0.1); /* Color de fondo al resaltar */
  border: 2px solid #007bff; /* Cambiar el color del borde cuando está resaltado */
}

.short-url-editable:focus {
  outline: none; /* Elimina el contorno predeterminado al hacer foco */
  background-color: rgba(0, 123, 255, 0.1); /* Color de fondo al enfocar */
  border-color: #0056b3; /* Cambia el color del borde al enfocar */
}.api-key-block{
  background-color: gainsboro;
  border-radius: 10px;
  padding: 8px;
  width:70vw;}