body {
    margin: 0;
    padding: 0;
    background: #f4f6f9;
    font-family: Arial, sans-serif;
}

.contenedor-login {
    height: 100vh;
    background-color: #f7f9fb;
    flex-direction: column;
}

.login-form-wrapper {
    background: #ffffff;
    padding: 2rem 2.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
    text-align: center;
}

h3 {
    color: #333;
    margin-bottom: 1.5rem;
}

.form-group {
    text-align: left;
}

.form-control {
    border-radius: 5px;
    padding: 0.75rem;
    border: 1px solid #ced4da;
    transition: border-color 0.3s ease;
}

.form-control:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.is-invalid {
    border-color: #dc3545;
    background-color: #fff5f5;
}

.invalid-feedback {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.btn {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 5px;
}

.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
    border-color: #004085;
}.separador{
    width: 100px;
    height: 1px;
    background-color: lightgray;
}
.boton-register{
    cursor: pointer;
    color: black;
    text-decoration: none;

}.boton-register:hover{
    background: lightgray;
    color: #494444;
}