.navbarr{
    position: fixed;
    background-color: rgba(237, 236, 246, 0.652);
    top: 0;
    right: 0;
    left: 0;
   
    backdrop-filter: blur(7px);
    z-index: 40;
    list-style: none;
    font-family: helvetica;
    border-bottom: 1px solid rgb(112, 110, 110);
}.nav-item{
    color: black;
    text-decoration: none;
    margin-left: 45px;
    color: rgb(68, 65, 65);
}
.logo{
    color: black;
    text-decoration: none;

}.hamburguer-button .bar{
    width: 30px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    margin: 5px;
    transition: 0.3s ease-in, opacity 0.4s ease;
}.hamburguer-button{
    cursor: pointer;
}.hamburguer-button.open .bar:nth-child(1){
    transform: translateY(8px)rotate(45deg);
}.hamburguer-button.open .bar:nth-child(2){
    display: none;
}.hamburguer-button.open .bar:nth-child(3){
    transform: rotate(-40deg);
}.nav-links{display: none;}
.contenedor-navbar{
    flex-wrap: wrap;
}
.nav-links{
    text-align: center;
    color: rgb(215, 209, 209);
    font-weight: bold;
}
@media (min-width: 770px ){
    .hamburguer-button{
        display: none;
    }
    .nav-links{display: flex;}
}@media (max-width: 570px ){
    .nav-links.show{
        
        flex-direction: column;
    }
}@media (max-width: 770px ){
    .nav-links.show{
        
        text-align: center;
        justify-content: center;
    }
}
.nav-links.show{
    display: flex;

}.nav-links.show .nav-item{
    margin-top: 15px;
    margin-bottom: 5px;
}
.navbar .dropdown-toggle::after {
    content: '';
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: middle;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-left: 0.3em solid transparent;
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
  }
  
  /* Cuando el dropdown está abierto */
  .navbar .dropdown.show .dropdown-toggle::after {
    transform: rotate(0deg);
  }.empezar-gratis{
    border: 2px solid blueviolet;
    text-decoration: none;
    color: rgb(62, 61, 61);
    margin:5px;
    font-weight: normal;
  }.nav-link:hover{
    color: blue;
  }
  .nav-item:hover{
    color: rgb(6, 98, 246);
    transition: 0.1s ease-in;
  }
  .empezar-gratis:hover{
    background: linear-gradient(to right bottom,rgb(212, 0, 255),rgb(80, 10, 220));
    color: white;
    transition: 0.2s ease-in;
    transform: scale(1.05);
    font-weight: normal;
  }
  