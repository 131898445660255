.hero {
    background: linear-gradient(to right, #5c1658, #940488);
    color: white;
    padding: 80px 0;
    animation: fadeIn 1.5s ease;
}

/* Animaciones */
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.feature-icon {
    font-size: 3rem;
    color: #4f027c;
    margin-bottom: 20px;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

/* Animación para botones */
.btn:hover {
    transform: scale(1.05);
    transition: 0.3s;
}

.shorten-url {
    padding: 40px 0;
}

.input-url {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.btn-short {
    background-color: #4f027c;
    color: white;
    
}

.testimonials .blockquote {
    margin: 30px auto;
    max-width: 600px;
    background: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    border-left: 5px solid #007bff;
}

.monitor .container {
    display: flex;
    align-items: center;
    padding: 50px 0;
}

@media(max-width: 768px) {
    .monitor .container {
        flex-direction: column;
    }
    .input-url {
        width: 100%;
        
    }
}
.tutorial-section {
    padding: 50px 0;
    background-color: #f9f9f9;
}

.tutorial-section h3 {
    font-size: 2.5rem;
    color: #4f027c;
    margin-bottom: 40px;
}

.instruction {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.instruction-text {
    flex: 1;
    max-width: 500px;
}

.instruction-text h4 {
    font-size: 1.8rem;
    color: #333;
    margin-bottom: 15px;
}

.instruction-text p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
}

.instruction-image {
    flex: 1;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.instruction-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

@media(min-width: 768px){
.instruction-image img:hover {
    transform: scale(1.25);
}
}
@media (max-width: 768px) {
    .instruction {
        flex-direction: column;
        text-align: center;
    }
    .instruction-image, .instruction-text {
        max-width: 100%;
    }
}